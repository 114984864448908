//////////////////////////////////////////////////
//  Web interface functions
//////////////////////////////////////////////////

import * as db from "./WebDB.js";
import { WebVideo } from "./WebVideo.js";
import Record from "../editor/ui/Record";
import { absoluteURL } from "../utils/lib.js";

// MediaRecorder node which records audio
let audioRecorder = null;
// AudioAnalyser node which gives us data to calculate volume
let audioAnalyser = null;
// stores latest audio recording URL
let latestAudioURL = null;
// stores latest recorded audio data to be converted to a blob
const latestAudioChunks = [];
// buffers audio data to calculate volume
let audioVolumeBuffer = null;

let webVideo = null;

const audioContext = new AudioContext();
const audioBuffers = {};
const audioSources = {};

// calculates the volume level of a given audio data array buffer
// used to display volume level preview in the audio recorder
function calculateVolumeLevel(audioData) {
  let sum = 0;
  for (let i = 0; i < audioData.length; i++) {
    sum += audioData[i];
  }
  const average = sum / audioData.length;

  // Map to range 0.0 to 1.0
  const volume = average / 255;
  return volume;
}

export async function setupMediaRecording() {
  if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
    console.log("Media recording unsupported!");
    return;
  }

  try {
    const audioStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    const recorderAudioContext = new AudioContext();
    const audioStreamSource =
      recorderAudioContext.createMediaStreamSource(audioStream);
    audioAnalyser = recorderAudioContext.createAnalyser();
    audioStreamSource.connect(audioAnalyser);
    audioRecorder = new MediaRecorder(audioStream);
    audioRecorder.addEventListener("dataavailable", (e) =>
      latestAudioChunks.push(e.data)
    );
    audioRecorder.addEventListener("stop", async () => {
      const audioBlob = new Blob(latestAudioChunks, {
        type: "audio/webm",
      });

      Record.setButtonsEnabled(false);

      try {
        // Convert blob to base64
        const reader = new FileReader();
        const base64Promise = new Promise((resolve) => {
          reader.onloadend = () => {
            const base64Data = reader.result.split(",")[1];
            resolve(base64Data);
          };
        });
        reader.readAsDataURL(audioBlob);
        const base64Audio = await base64Promise;

        // Generate MD5 hash for the audio data
        const md5Hash = await db.getMD5(base64Audio);
        const soundName = `RECORDING_${md5Hash}`;

        // Create a FileReader to read the Blob as an ArrayBuffer
        const arrayBufferReader = new FileReader();
        const arrayBufferPromise = new Promise((resolve) => {
          arrayBufferReader.onloadend = async () => {
            const audioBuffer = await audioContext.decodeAudioData(
              arrayBufferReader.result
            );
            audioBuffers["__recording__"] = audioBuffer;

            // Save to database
            await db.executeStatementFromJSON({
              stmt: `INSERT OR REPLACE INTO RECORDED_SOUNDS (MD5, NAME, AUDIO_DATA, DURATION) VALUES (?, ?, ?, ?)`,
              values: [md5Hash, soundName, base64Audio, audioBuffer.duration],
            });

            Record.soundname = soundName;
            resolve();
          };
        });
        arrayBufferReader.readAsArrayBuffer(audioBlob);
        await arrayBufferPromise;
      } catch (err) {
        console.log("Audio recording error!", err);
        return;
      } finally {
        Record.setButtonsEnabled(true);
      }
    });

    const bufferLength = audioAnalyser.frequencyBinCount;
    audioVolumeBuffer = new Uint8Array(bufferLength);
  } catch (err) {
    console.log("Audio recording error!", err);
  }
}

export function audioRecorderAvailable() {
  return audioRecorder !== null;
}

export function videoRecorderAvailable() {
  return true;
}

function stopRecording() {
  if (audioRecorder.state !== "inactive") {
    audioRecorder.stop();
  }
  if (latestAudioURL !== null) {
    latestAudioURL = null;
  }
}

export default class Web {
  // Database functions
  static stmt(json, fcn) {
    // json is an object with the format:
    // {
    //     "stmt": "<SQL statement to run with slots for values below>",
    //     "values": [<list of values to be plugged into the statement>],
    // }
    (async () => {
      const result = await db.executeStatementFromJSON(json);
      await db.saveDB();
      if (fcn) fcn(result);
    })();
  }

  static query(json, fcn) {
    // json is an object with the format:
    // {
    //     "stmt": "<SQL statement to run with slots for values below>",
    //     "values": [<list of values to be plugged into the statement>],
    // }
    (async () => {
      const result = await db.executeQueryFromJSON(json);
      if (fcn) fcn(result);
    })();
  }

  static setfield(db, id, fieldname, val, fcn) {
    if (fcn) fcn();
  }

  // IO functions

  static cleanassets(ft, fcn) {
    if (fcn) fcn();
  }

  static getmedia(file, fcn) {
    (async () => {
      var content = await db.readProjectFile(file);
      if (fcn) fcn(content);
    })();
  }

  static getmediadata(key, offset, len, fcn) {
    if (fcn) fcn();
  }

  static processdata(key, off, len, oldstr, fcn) {
    if (fcn) fcn();
  }

  static getsettings(fcn) {
    fcn("path,0,NO,NO");
  }

  static getmediadone(file, fcn) {
    if (fcn) fcn();
  }

  static setmedia(content, ext, fcn) {
    (async () => {
      var name = await db.getMD5(content);
      const filename = `${name}.${ext}`;
      await db.saveToProjectFiles(filename, content, {
        encoding: "base64",
      });
      if (fcn) fcn(filename);
    })();
  }

  static setmedianame(str, name, ext, fcn) {
    const filename = `${name}.${ext}`;
    db.saveToProjectFiles(filename, str, { encoding: "base64" });
    if (fcn) fcn(filename);
  }

  static getmd5(str, fcn) {
    (async () => {
      var name = await db.getMD5(str);
      if (fcn) fcn(name);
    })();
  }

  static remove(str, fcn) {
    (async () => {
      try {
        if (str.startsWith("RECORDING_")) {
          // Remove recorded sound from database
          const md5 = str.replace("RECORDING_", "");
          await db.executeStatementFromJSON({
            stmt: `DELETE FROM RECORDED_SOUNDS WHERE MD5 = ?`,
            values: [md5],
          });
        }
        if (fcn) fcn();
      } catch (error) {
        console.error("Error removing sound:", error);
        if (fcn) fcn();
      }
    })();
  }

  static getfile(str, fcn) {
    if (fcn) fcn("");
  }

  static setfile(name, str, fcn) {
    if (fcn) fcn();
  }

  // Sound functions

  static registerSound(dir, name, fcn) {
    (async () => {
      try {
        let audioBuffer;
        if (name.startsWith("RECORDING_")) {
          // Load recorded sound from database
          const md5 = name.replace("RECORDING_", "");
          const result = JSON.parse(
            await db.executeQueryFromJSON({
              stmt: `SELECT AUDIO_DATA, DURATION FROM RECORDED_SOUNDS WHERE MD5 = ?`,
              values: [md5],
            })
          );

          if (result.length > 0 && result[0].values.length > 0) {
            const [audioData, duration] = result[0].values[0];

            // Convert base64 to array buffer
            const binaryString = atob(audioData);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }

            audioBuffer = await audioContext.decodeAudioData(bytes.buffer);
            audioBuffers[name] = audioBuffer;
            if (fcn) fcn(name, duration);
            return;
          }
        }

        // Handle regular sounds
        const url = absoluteURL(dir + name);
        const response = await fetch(url);
        const arrayBuffer = await response.arrayBuffer();
        audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        audioBuffers[name] = audioBuffer;
        if (fcn) fcn(name, audioBuffer.duration);
      } catch (error) {
        console.error("Error registering sound:", error);
        if (fcn) fcn("error");
      }
    })();
  }

  static playSound(name, onSoundEnd) {
    if (audioSources[name]) {
      audioSources[name].stop();
    }

    audioSources[name] = audioContext.createBufferSource();
    audioSources[name].buffer = audioBuffers[name];
    audioSources[name].connect(audioContext.destination);
    audioSources[name].addEventListener("ended", function () {
      this.stop();
      audioSources[name] = null;
      if (onSoundEnd) onSoundEnd();
    });
    audioSources[name].start();
  }

  static stopSound(name, fcn) {
    if (audioSources[name]) {
      audioSources[name].stop();
    }
    if (fcn) fcn();
  }

  // Web Wiew delegate call backs

  static sndrecord(fcn) {
    if (audioRecorder === null) {
      console.log("Audio recorder not available");
      if (fcn) fcn(false);
      return;
    }

    stopRecording();

    latestAudioChunks.length = 0;

    audioRecorder.start();
    if (fcn) fcn(true);
  }

  static recordstop(fcn) {
    if (audioRecorder === null) {
      console.log("Audio recorder not available");
      if (fcn) fcn(false);
      return;
    }

    stopRecording();

    if (fcn) fcn(true);
  }

  static volume(fcn) {
    if (audioVolumeBuffer === null) {
      console.log("Audio volume not available");
      if (fcn) fcn(0);
      return;
    }

    audioAnalyser.getByteFrequencyData(audioVolumeBuffer);
    const volume = calculateVolumeLevel(audioVolumeBuffer);

    if (fcn) fcn(volume);
  }

  static startplay(fcn) {
    Web.playSound("__recording__");
    if (fcn) fcn(audioBuffers["__recording__"].duration);
  }

  static stopplay(fcn) {
    Web.stopSound("__recording__");
    if (fcn) fcn();
  }

  static recorddisappear(b, fcn) {
    if (fcn) fcn();
  }

  // Record state
  static askpermission() {
    console.log("askpermission");
  }

  // camera functions

  static hascamera() {
    return videoRecorderAvailable();
  }

  static startfeed(data, fcn) {
    if (webVideo === null) {
      webVideo = new WebVideo(data);
      webVideo.show();
    }

    if (fcn) fcn();
  }

  static stopfeed(fcn) {
    if (webVideo !== null) {
      webVideo.hide();
      webVideo = null;
    }

    if (fcn) fcn();
  }

  static choosecamera(mode, fcn) {
    // This is not needed for the web version
    if (fcn) fcn();
  }

  static captureimage(fcn) {
    if (webVideo !== null) {
      // The image is returned as a data URL
      const imgDataURL = webVideo.snapshot();
      if (imgDataURL) {
        // we just want the base64 encoded image data without the header
        let rawImgData = imgDataURL.split(",")[1];
        Camera.processimage(rawImgData);
      }
    }

    if (fcn) fcn();
  }

  static hidesplash(fcn) {
    if (fcn) fcn();
  }

  static trace(str) {
    console.log("trace");
  }

  static parse(str) {
    console.log("parse");
  }

  static tracemedia(str) {
    console.log("tracemedia");
  }

  ignore() {}

  ///////////////
  // Sharing
  ///////////////

  static createZipForProject(projectData, metadata, name, fcn) {
    if (fcn) fcn();
  }

  // Called on the JS side to trigger native UI for project sharing.
  // fileName: name for the file to share
  // emailSubject: subject text to use for an email
  // emailBody: body HTML to use for an email
  // shareType: 0 for Email; 1 for Airdrop
  // b64data: base-64 encoded .SJR file to share

  static sendSjrToShareDialog(fileName, emailSubject, emailBody, shareType) {
    console.log("sendSjrToShareDialog");
  }

  static registerLibraryAssets(version, assets, fcn) {
    if (fcn) fcn();
  }

  static duplicateAsset(path, name, fcn) {
    if (fcn) fcn();
  }

  // Name of the device/iPad to display on the sharing dialog page
  // fcn is called with the device name as an arg
  static deviceName(fcn) {
    if (fcn) fcn("Web");
  }

  static analyticsEvent(category, action, label) {
    console.log("analyticsEvent");
  }

  static setAnalyticsPlacePref(preferredPlace) {
    console.log("setAnalyticsPlacePref");
  }

  static setAnalyticsPref(key, value) {
    console.log("setAnalyticsPref");
  }
}
